import React from 'react'


export default function HeaderIcons() {
    return (
        <>
            <div className="header-icons row justify-content-end text-center text-sm-right align-items-center mr-2">
                <div className="col-8 col-sm-auto">
                    <a href="http://www.webtenerife.com/que-visitar/otros-espacios-naturales/parque+rural+de+teno.htm">
                        <span className="sr-only">Parque Rural Teno</span>
                        <span className="icon-logo-parque-rural-teno"/>
                    </a>
                </div>
                <div className="col-4 col-sm-auto">
                    <a href="https://www.tenerife.es">
                        <span className="sr-only">Cabildo de Tenerife</span>
                        <span className="icon-logo-cabildo-tfe"/>
                    </a>
                </div>
            </div>
        </>
    )
}

import { useStaticQuery, graphql } from "gatsby"
export const useCookiesData = () => {
	const { site } = useStaticQuery(graphql`
		query CookieInfoQuery {
			site {
				siteMetadata {
					title
					cookiesPolicyArticleInfo {
						lang
						path
					}
					termsAndConditionsArticleInfo {
						lang
						path
					}
					gtmTrackingId
					cookies {
						translations {
							de {
								consentModal {
									title
									showPreferencesBtn
									footer
									description
									acceptNecessaryBtn
									acceptAllBtn
								}
								preferencesModal {
									acceptAllBtn
									closeIconLabel
									acceptNecessaryBtn
									savePreferencesBtn
									sections {
										description
										linkedCategory
										title
									}
									serviceCounterLabel
									title
								}
							}
							en {
								consentModal {
									acceptAllBtn
									acceptNecessaryBtn
									description
									footer
									showPreferencesBtn
									title
								}
								preferencesModal {
									acceptAllBtn
									acceptNecessaryBtn
									closeIconLabel
									savePreferencesBtn
									sections {
										description
										linkedCategory
										title
									}
									serviceCounterLabel
									title
								}
							}
							es {
								consentModal {
									acceptAllBtn
									acceptNecessaryBtn
									description
									footer
									showPreferencesBtn
									title
								}
								preferencesModal {
									acceptAllBtn
									acceptNecessaryBtn
									closeIconLabel
									savePreferencesBtn
									sections {
										description
										linkedCategory
										title
									}
									serviceCounterLabel
									title
								}
							}
						}
					}
				}
			}
		}
	`);

	return site
}

import React from "react"
import { Link as I18nLink, I18nextContext, withTranslation } from "gatsby-plugin-react-i18next";
import { Location } from "@reach/router"
import ContentContext from "../context/ContentContext";
import { Link } from "gatsby"

class LangSelector extends React.Component {
	static contextType = I18nextContext;

	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false,
		};
	}

	toggleDropdown = () => {
		this.setState({ dropdownOpen: !this.state.dropdownOpen });
	};

	render() {
		const checkUrlAndAddSlash = (url) => {
			return url.slice(-1) !== '/' ? url + '/' : url;
		};

		const currentLanguage = this.context.language;

		return (
			<div className="dropdown language-selector">
				<button
					className="btn dropdown-toggle"
					type="button"
					id="languageDropdown"
					onClick={this.toggleDropdown}
					aria-expanded={this.state.dropdownOpen}
				>
					{currentLanguage.toUpperCase()}
				</button>
				{this.state.dropdownOpen && (
					<ul className="dropdown-menu show" aria-labelledby="languageDropdown">
						<ContentContext.Consumer>
							{content => {
								if (content.translations) {
									return this.context.languages.map((lng) => {
										if (lng === currentLanguage) return null;
										const translation = content.translations.find(translation => translation.lang === lng);
										if (translation) {
											const pathWithSlash = checkUrlAndAddSlash(translation.path);
											return (
												<li className="dropdown-item" key={lng}>
													<Link to={pathWithSlash} className="nav-link nav-link-language">
														{lng.toUpperCase()}
													</Link>
												</li>
											);
										} else {
											return <React.Fragment key={lng} />;
										}
									});
								} else {
									return (
										<Location>
											{({ location }) => {
												const language = location.pathname.replace("/" + this.context.language, "");
												return this.context.languages.map((lng) => {
													if (lng === currentLanguage) return null;
													return (
														<li className="dropdown-item" key={lng}>
															<I18nLink
																to={language + location.search || ''}
																language={lng}
																className="nav-link nav-link-language"
															>
																{lng.toUpperCase()}
															</I18nLink>
														</li>
													);
												});
											}}
										</Location>
									);
								}
							}}
						</ContentContext.Consumer>
					</ul>
				)}
			</div>
		);
	}
}

export default withTranslation('LangSelector')(LangSelector);
